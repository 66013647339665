import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "pretitle": "Appearance",
  "title": "Clockwise 322",
  "date": "2019-11-27T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.18181818181818%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIDBP/aAAwDAQACEAMQAAABfNXjDdgAX//EABoQAAICAwAAAAAAAAAAAAAAAAASATECAxH/2gAIAQEAAQUCy6upmJqLP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPwE1f//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIv/aAAgBAgEBPwEcz//EABkQAAEFAAAAAAAAAAAAAAAAAAABESAxYf/aAAgBAQAGPwJWsyP/xAAaEAACAgMAAAAAAAAAAAAAAAABERBxACFB/9oACAEBAAE/Iai1hkTdnIA4j//aAAwDAQACAAMAAAAQzB//xAAWEQEBAQAAAAAAAAAAAAAAAAABECH/2gAIAQMBAT8QGGf/xAAWEQEBAQAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8QkiZ//8QAHBABAAEFAQEAAAAAAAAAAAAAAREAECExcUFR/9oACAEBAAE/EOkMPtDYjGVbTjftiKkmgBBPLf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "clockwise",
          "title": "clockwise",
          "src": "/static/fcd50cb48fdcf627fa6775fe882caded/ddced/clockwise.jpg",
          "srcSet": ["/static/fcd50cb48fdcf627fa6775fe882caded/35f54/clockwise.jpg 275w", "/static/fcd50cb48fdcf627fa6775fe882caded/d7854/clockwise.jpg 550w", "/static/fcd50cb48fdcf627fa6775fe882caded/ddced/clockwise.jpg 1100w", "/static/fcd50cb48fdcf627fa6775fe882caded/670dc/clockwise.jpg 1650w", "/static/fcd50cb48fdcf627fa6775fe882caded/93992/clockwise.jpg 2088w"],
          "sizes": "(max-width: 1100px) 100vw, 1100px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I was privileged enough to be a guest on Clockwise this week. We talked about the California DMV selling your personal data, the video-recording-and-sharing-service Loom, Thanksgiving & Black Friday deals, and “sharenting.”`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.relay.fm/clockwise/322"
      }}>{`Give it a listen on Relay`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      